import "./Footer.scss";
import sxlogo2 from "../../Assets/Images/Branding/sx-logo-2.png";
import dsgtlogotext from "../../Assets/Images/Branding/dsgt-logo-text.png";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Footer = (params) => {
    return (
        <div id="footer">
            <div className="footer-wrapper">
                <div className="footer-section">
                    <div className="footer-header">
                        <h2 className="minor">
                        Made with ♥
                        by the DSGT Tech team.
                        </h2>
                    </div>
                </div>
                {/* <div className="footer-section">
                    <div className="footer-header">
                        <LazyLoadImage
                            threshold={400}
                            effect="blur"
                            className="sxlogo"
                            src={sxlogo2}
                            alt="sxlogo"
                        />
                    </div>
                    <div className="footer-links">
                        <a className="mini" href="https://www.startup.exchange/about" target="_blank">
                            About Us
                        </a>
                        <a className="mini" href="https://instagram.com/startupexchange" target="_blank">
                            Instagram
                        </a>
                        <a className="mini" href="https://twitter.com/startupxchange" target="_blank">
                            Twitter
                        </a>
                        <a className="mini" href="mailto:hello@startup.exchange">
                            Contact
                        </a>
                    </div>
                </div> */}
                <div className="footer-section">
                    <div className="footer-header">
                        <LazyLoadImage
                            threshold={400}
                            effect="blur"
                            className="dsgtlogo"
                            src={dsgtlogotext}
                            alt="dsgt"
                        />
                        <h1 className="major">DSGT</h1>
                    </div>
                    <div className="footer-links">
                        <a className="mini" href="https://datasciencegt.org/" target="_blank">
                            About Us
                        </a>
                        <a className="mini" href="https://www.instagram.com/datasciencegt/" target="_blank">
                            Instagram
                        </a>
                        <a className="mini" href="https://www.linkedin.com/company/dsgt/" target="_blank">
                            LinkedIn
                        </a>
                        <a className="mini" href="https://github.com/DataScience-GT" target="_blank">
                            Github
                        </a>
                        <a className="mini" href="mailto:info@hacklytics.io" target="_blank">
                            Contact
                        </a>
                    </div>
                </div>
                <div className="footer-section">
                    <div className="footer-header">
                        <h1 className="major">Hacklytics</h1>
                    </div>
                    <div className="footer-links">
                        <a className="mini" href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf">
                            MLH Code of Conduct
                        </a>
                        <a className="mini" href="">
                            Previous Websites
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Footer;