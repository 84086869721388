import "./Faq.scss";
import airplane from "../../Assets/Images/Other/airplane.png";

const Faq = (params) => {
    const identifier = "faq-checkbox-" + params.identifier;

    return (
        <div className="faq">
            <div className="airplane-left">
                <img
                    src={airplane}
                    alt="airplane-pic"
                />
            </div>
            <input 
                id={identifier} 
                className="faq-checkbox" 
                type="checkbox" 
            />
            <label className="faq-label" htmlFor={identifier}>
                <span className="faq-question">{params.question}</span>
                <span className="faq-arrow">↓</span>
            </label>
            <p className="faq-answer">{params.children}</p>
        </div>
    );
};

export default Faq;