import "./Home.scss";

import React, { useEffect, useState } from "react";

// react imports
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// components and assets
import Section from "../../Components/Section";
import ScheduleSection from "../../Components/ScheduleSection";
import TrackCard from "../../Components/TrackCard";
import SpeakerCard from "../../Components/SpeakerCard";
import Faq from "../../Components/FAQ";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import myearth from "../../Assets/Videos/my-earth.mp4";

import dsgtlogotext from "../../Assets/Images/Branding/new-nobg.png";
import sxlogotext from "../../Assets/Images/Branding/sx-logo-2.png";
import mlhbadge from "../../Assets/Images/Other/mlh-trust-badge-2024-black 1.png";

import prizetop3 from "../../Assets/Images/Prizes/prize-luggage.png";
import prizeevent from "../../Assets/Images/Prizes/prize-event.png";

import krishi from "../../Assets/Headshots/IMG-5009-krishi.png";
import vicente from "../../Assets/Headshots/IMG-5010-vicente.png";
import emma from "../../Assets/Headshots/IMG-5013-emma.png";
import archie from "../../Assets/Headshots/IMG-5014-archie.png";
import shreiyas from "../../Assets/Headshots/Shreiyas.png";
import john from "../../Assets/Headshots/IMG-5017-john.png";
import srijan from "../../Assets/Headshots/headshot-jan-2020.png";
import jake from "../../Assets/Headshots/jake.png";
import yasin from "../../Assets/Headshots/yasin.jpeg";
import brandon from "../../Assets/Headshots/brandon.png";
import rahul from "../../Assets/Headshots/rahul.png";
import xiquan from "../../Assets/Headshots/xiquan_THD.JPG";
import leo from "../../Assets/Headshots/leo.jpg";
import alfredo from "../../Assets/Headshots/alfredo.jpeg";

import assurant from "../../Assets/White_Logos/assurant.png";
import amazon from "../../Assets/White_Logos/Amazon.png";
import carelon from "../../Assets/White_Logos/carelon2.png";
import createx from "../../Assets/White_Logos/CREATE-X.png";
import deshawco from "../../Assets/White_Logos/DE_Shaw.png";
import elastic from "../../Assets/White_Logos/elastic.png";
import googlecloud from "../../Assets/White_Logos/Google Cloud.png";
import google from "../../Assets/White_Logos/Google.png";
import inteldev from "../../Assets/White_Logos/Intel Developer Cloud.png";
import intel from "../../Assets/White_Logos/Intel.png";
import johndeere from "../../Assets/White_Logos/John Deere.png";
import telora from "../../Assets/White_Logos/telora.png";
import traversaal from "../../Assets/White_Logos/traversaal.png";
import wolfram from "../../Assets/White_Logos/Wolfram.png";
import nsa from "../../Assets/Images/Sponsors/nsa.png";
import coc from "../../Assets/Images/CommunityPartners/coc.png";
import datadays from "../../Assets/Images/CommunityPartners/Data Days.png";
import isye from "../../Assets/Images/CommunityPartners/isye.png";
import scheller2 from "../../Assets/Images/CommunityPartners/scheller2.png";
import onetrust from "../../Assets/White_Logos/OneTrust.png";
import microsoft from "../../Assets/White_Logos/Microsoft.png";
import kinetic from "../../Assets/White_Logos/Kinetic Atlanta.png";
import headstarter from "../../Assets/White_Logos/HeadStarter.png";
import archetype from "../../Assets/White_Logos/Archetype AI.png";
import ideas from "../../Assets/Images/CommunityPartners/Ideas Logo.png";
import soonami from "../../Assets/Images/Sponsors/soonami2.png";
import lenovo from "../../Assets/Images/Sponsors/lenovo.png";
import brightdata from "../../Assets/White_Logos/brightdata.png";
import connectwise from "../../Assets/White_Logos/connectwise.png";

import Clouds from "../../Components/Clouds";

const Home = (params) => {

    const [isNavbarFixed, setIsNavbarFixed] = useState(false);

    const handleScroll = () => {
        const bannerHeight = document.querySelector('.travel-reimbursement-banner').clientHeight;
        const scrollTop = window.scrollY;

        if (scrollTop > bannerHeight / 2) {
            setIsNavbarFixed(true);
        } else {
            setIsNavbarFixed(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="home-page">
            <div className="travel-reimbursement-banner">
                <p className="text">Applications to Hacklytics 2024 have CLOSED.</p>
            </div>
            <Navbar isFixed={isNavbarFixed}/>

            <div className="mlh-section">
                <div className="mlh-wrapper">
                    <a className="mlh-badge" href="https://mlh.io/">
                        <LazyLoadImage effect="blur" src={mlhbadge} alt="mlh badge" />
                    </a>
                </div>
            </div>

            <Section id="hero">
                <div className="hero-flex">
                    <Clouds ScrollDirection="Left"/>
                    <div className="flex-center">
                        <div className="scene-container">
                            <video className='videoTag' autoPlay loop muted>
                                <source src={myearth} type='video/mp4' />
                            </video>
                        </div>

                        <div className="flex-overlay">
                            <h2 className="hero-major">HACKLYTICS 2024</h2>
                            <h1 className="hero-minor">Around The World</h1>
                            <h3 className="hero-date">February 9-11, 2024</h3>
                            <div className="flex-row">
                                {/* <a
                                    className="hero-button"
                                    href="https://7px1lai8dh3.typeform.com/to/UTCsBU74"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                Applications are CLOSED!
                                </a> */}
                            </div>
                        </div>
                    </div>
                    <div className="presented-by-flex">
                        <h2 className="presented-by-text">Presented by</h2>
                        <div className="presented-by-imgs">
                            <img className="dsgt-logo" src={dsgtlogotext}/>
                            {/* <img className="sx-logo" src={sxlogotext}/> */}
                        </div>
                    </div>
                </div>
            </Section>

            <Section id="about" Coordinates="True">
                <div className="split-flex">
                    <h1 className="major"><em><strong>About</strong></em></h1>
                    <p className="mini">
                        Join us for an extraordinary hackathon experience at Hacklytics 2024 
                        as we embark on a global journey with our theme, 
                        <strong>"Around the World"!</strong>{" "}
                        Explore the intersection of technology, innovation, and diverse cultures 
                        as you tackle real-world challenges that transcend borders.
                        <br />
                        <br />
                        Harness the power of technology to address pressing global issues, 
                        from sustainability and education to healthcare and beyond. Through this 
                        inspiring journey, you'll not only showcase your skills but also forge 
                        lasting connections with like-minded individuals who share your passion 
                        for making a difference in the world.
                    </p>
                </div>
                <a className="continue-reading" href="#whenwherewhatis">
                    <span className="text">What is/when is Hacklytics?</span>
                    <span className="arrow">↓</span>
                </a>
            </Section>

            <Section id="whenwherewhatis" Coordinates="True">
                <div className="split-flex">
                    <div className="whenwhere-flex">
                        <h3 className="mini">When/Where is Hacklytics?</h3>
                        <div className="yetanotherflex">
                            <div className="flex-left">
                                <div className="map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212247.46455110525!2d-84.67596646718748!3d33.777029200000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b781ec0ab42ea5%3A0x16eec927f37b40ec!2sKlaus%20Advanced%20Computing%20Building!5e0!3m2!1sen!2sus!4v1656647278486!5m2!1sen!2sus"
                                        width="300"
                                        height="300"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        title="Klaus Advanced Computing Building"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                            <div className="flex-right">
                                <h2 className="major">Feb 9 - 11, 2024</h2>
                                <h3 className="location">
                                    <a href="https://goo.gl/maps/W7uum6WcHQ6bTrJXA">
                                        Klaus Advanced Computing Building
                                    </a>{" "}
                                </h3>
                            </div>
                        </div>
                        <h4 className="details">
                            Hacklytics 2024 will be completely in person.
                            <br />
                            <br />
                            More info on travel reimbursements coming soon.
                        </h4>
                    </div>
                    <div className="whatis-flex">
                        <h2 className="major">What is Hacklytics?</h2>
                        <p className="mini">
                            Hacklytics is a 36-hour data science-focused hackathon hosted by
                            Data Science @ GT, the largest data science club at Georgia Tech.
                            Each year, hackers across the world join us in creating innovative
                            data-driven solutions to real problems.
                            <br />
                            <br />
                            Our goal is to equip you with hands-on data science experience and
                            help you create impactful projects. At the same time, you can
                            connect with hackers from various backgrounds, build a diverse
                            network, and have fun!
                        </p>
                    </div>
                </div>
                <a className="continue-reading" href="#schedule">
                    <span className="text">Schedule</span>
                    <span className="arrow">↓</span>
                </a>
            </Section>

            <Section id="schedule" Coordinates="True">
                <div className="schedule-flex">
                    <h1 className="major">Schedule</h1>
                    <ScheduleSection />
                </div>
                <a className="continue-reading" href="#tracks">
                    <span className="text">Tracks</span>
                    <span className="arrow">↓</span>
                </a>
            </Section>

            <Section id="tracks" Coordinates="True">
                <div className="tracks-flex">
                    <h1 className="major">Tracks</h1>
                    <p className="clickToFlip">Click cards to flip</p>
                    <TrackCard identifier="a1" track="SPORTS">
                        From historical data and fundamental scorekeeping to algorithmic performance and player 
                        statistics, data analytics is an integral part of sports. In this track, you will be 
                        working on sports analytics challenges that will take your data science and machine 
                        learning skills to the next level.
                    </TrackCard>
                    <TrackCard identifier="a2" track="HEALTHCARE">
                        Health data science is a growing field that incorporates health informatics, data science, 
                        analytics, and computational modeling to assess large volumes of data from healthcare-related 
                        sources. In this track, you will be analyzing some of these datasets to help solve crucial, 
                        real-world problems in public health or the biomedical sciences.
                    </TrackCard>
                    <TrackCard identifier="b1" track="GENERATIVE AI" subtext="Brought to you by Microsoft">
                        In this track, participants will delve into the exciting domain of Generative AI, 
                        exploring its potential applications. Whether you're interested in creating novel artworks, 
                        generating innovative solutions to complex problems, or pushing the boundaries of what 
                        AI can create, this track offers a unique opportunity to harness the power of Generative AI. 
                        Participants will work on projects with objectives ranging from designing generative models 
                        for creative endeavors to solving real-world challenges through innovative AI-powered solutions.
                    </TrackCard>
                    <TrackCard identifier="b2" track="FINANCE">
                        The financial industry, which includes both traditional financial institutions as well as 
                        fintech companies, deals with large volumes of unique types of data and comes with some 
                        peculiarities that other industries don’t share. In this track, you will be working on 
                        data science challenges that touch almost every area of finance and banking, such as 
                        trading decisions, market forecasting, customer sentiment, and more.
                    </TrackCard>
                </div>
                <a className="continue-reading" href="#prizes">
                    <span className="text">Prizes</span>
                    <span className="arrow">↓</span>
                </a>
            </Section>

            <Section id="prizes" Coordinates="True">
                <div className="prizes-flex">
                    <h2 className="major">Prizes</h2>
                    <p className="mini">
                        We will be giving out prizes to the top teams in each track and to the
                        best overall teams. The prizes HAVE BEEN ANNOUNCED!
                    </p>
                    <p className="mini">View full prize information {" "}
                        <a style={{ color: "blue" }} href="https://docs.google.com/spreadsheets/d/1LiAXDE3JOKj1vxMY7tIkaY_o9urTQGinPkJqb0q4Vm8/edit?usp=sharing" target="_blank">
                            here.
                        </a>
                    </p>

                    <div className="prize-grid">
                        <div className="prize-card-first">
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="prize-img"
                                src={prizetop3}
                                alt="1st place"
                            />
                            <h1 className="prize-major">1st Place</h1>
                            <h2 className="prize-mini" style={{ fontSize: '1.4em' }}>
                                1st - $1000 cash or equivalent in electronics/voucher per team member
                            </h2>
                        </div>

                        <div className="prize-card-second">
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="prize-img"
                                src={prizetop3}
                                alt="2nd place"
                            />
                            <h1 className="prize-major">2nd Place</h1>
                            <h2 className="prize-mini" style={{ fontSize: '1.4em' }}>
                                2nd - $625 cash or equivalent in electronics/voucher per team member
                            </h2>
                        </div>

                        <div className="prize-card-third">
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="prize-img"
                                src={prizetop3}
                                alt="3rd place"
                            />
                            <h1 className="prize-major">3rd Place</h1>
                            <h2 className="prize-mini" style={{ fontSize: '1.4em' }}>
                                3rd - $400 cash or equivalent in electronics/voucher per team member
                            </h2>
                        </div>
                        <br />

                        <div className="prize-card-event">
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="prize-img"
                                src={prizeevent}
                                alt="prize event"
                            />
                            <h1 className="prize-major">Sports Prize</h1>
                            <h2 className="prize-mini">
                                1st - $200 cash or equivalent in electronics/voucher per team member
                                <br></br>
                                <br></br>
                                2nd - $125 cash or equivalent in electronics/voucher per team member
                                <br></br>
                                <br></br>
                                3rd - $50 cash or equivalent in electronics/voucher per team member
                            </h2>
                        </div>
                        <div className="prize-card-event">
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="prize-img"
                                src={prizeevent}
                                alt="prize event"
                            />
                            <h1 className="prize-major">Health Prize</h1>
                            <h2 className="prize-mini">
                                1st - $200 cash or equivalent in electronics/voucher per team member
                                <br></br>
                                <br></br>
                                2nd - $125 cash or equivalent in electronics/voucher per team member
                                <br></br>
                                <br></br>
                                3rd - $50 cash or equivalent in electronics/voucher per team member
                            </h2>
                        </div>
                        <div className="prize-card-event">
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="prize-img"
                                src={prizeevent}
                                alt="prize event"
                            />
                            <h1 className="prize-major">Generative AI Prize</h1>
                            <h2 className="prize-mini">
                                1st - $200 cash or equivalent in electronics/voucher per team member
                                <br></br>
                                <br></br>
                                2nd - $125 cash or equivalent in electronics/voucher per team member
                                <br></br>
                                <br></br>
                                3rd - $50 cash or equivalent in electronics/voucher per team member
                            </h2>
                        </div>
                        <div className="prize-card-event">
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="prize-img"
                                src={prizeevent}
                                alt="prize event"
                            />
                            <h1 className="prize-major">Finance Prize</h1>
                            <h2 className="prize-mini">
                                1st - $200 cash or equivalent in electronics/voucher per team member
                                <br></br>
                                <br></br>
                                2nd - $125 cash or equivalent in electronics/voucher per team member
                                <br></br>
                                <br></br>
                                3rd - $50 cash or equivalent in electronics/voucher per team member
                            </h2>
                        </div>
                    </div>
                </div>
                <a className="continue-reading" href="#faq">
                    <span className="text">FAQ</span>
                    <span className="arrow">↓</span>
                </a>
            </Section>

            <Section id="faq" Coordinates="True">
                <div className="background-container"></div>
                <div className="faq-flex">
                    <h1 className="major">Got questions?</h1>
                    <div className="faq-grid">
                        <Faq identifier="a" question="Who can register?">
                            Any student currently enrolled in a University above the age of 18.
                            For any discrepancies, please reach out.
                        </Faq>
                        <Faq identifier="l" question="How do I apply for Travel Reimbursements?">
                            It’s pretty simple - simply fill out the Travel reimbursement form along 
                            with your Hacklytics application and we’ll review it! We have limited funds 
                            for reimbursement so APPLY NOW! The deadline to apply for travel 
                            reimbursements is January 6th. {
                                 " "
                            }
                            <a style={{ color: "blue" }} href="https://forms.gle/WjRMzERiPPnyZ12Y9" target="_blank">LINK.</a>
                        </Faq>
                        <Faq identifier="b" question="Where and when is it held?">
                            <a href="https://goo.gl/maps/W7uum6WcHQ6bTrJXA">
                            Klaus Advanced Computing Building
                            </a>{" "}
                            from 9th February to 11th February, 2024. The event will be fully in person.
                        </Faq>
                        <Faq identifier="c" question="Can you participate virtually?">
                            Unfortunately, no, Hacklytics 2024 is only in person this year. Sponsors
                            want to come see you work on your projects in person!
                        </Faq>
                        <Faq identifier="d" question="What is a data science hackathon?">
                            A datathon is a specific type of hackathon that focuses on data
                            science. You can choose any datasets, programming languages, APIs,
                            or algorithms you'd like to create visualizations, develop models,
                            derive insights, and do anything you believe to be impactful! The
                            sky's the limit!
                        </Faq>
                        <Faq identifier="e" question="How many people per team?">
                            A maximum of 4 members per team. However, you’re allowed to work
                            with fewer members as well!
                        </Faq>
                        <Faq identifier="f" question="What if I don’t have a team?">
                            Lots of people come in without teams! You can find people to form a
                            team with during our team-building event or through our Discord.
                        </Faq>
                        <Faq identifier="g" question="Is the event free?">
                            Yes! We also provide food and other goodies if you are in-person.
                        </Faq>
                        <Faq identifier="h" question="Is this event beginner friendly?">
                            Yes, we encourage beginners to apply to expand their knowledge! We
                            will have tons of skill-building workshops and experienced mentors
                            to help out with projects at the event. Overall, Hacklytics will be
                            a very enriching experience regardless of how experienced you are!
                        </Faq>
                        <Faq identifier="i" question="How are applicants selected?">
                            We evaluate your applications by looking into your interest in
                            participating and passion in the field of data!
                        </Faq>
                        <Faq
                            identifier="j"
                            question="What, other than coding, is going to happen at this event?"
                        >
                            Speaker talks, workshops, social events and a lot more!{" "}
                        </Faq>
                        <Faq identifier="k" question="How do I get in touch?">
                            Reach out to us at{" "}
                            <a href="mailto:info@hacklytics.io">info@hacklytics.io</a>
                        </Faq>
                    </div>
                </div>
                <a className="continue-reading" href="#speakers">
                    <span className="text">Speakers</span>
                    <span className="arrow">↓</span>
                </a>
            </Section>

            <Section id="speakers" Coordinates="True">
                <div className="speakers-flex">
                    <h1 className="major">Speakers</h1>
                    <div className="tri-flex">
                        <div className="speaker-card card-down">
                            <div className="speaker-bg"></div>
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="speaker-img"
                                src={srijan}
                                alt="Srijan Kumar"
                            />
                            <h1 className="speaker-major">
                                <a
                                className="link"
                                href="https://www.linkedin.com/in/srijankr/"
                                rel="noreferrer"
                                target="_blank"
                                >
                                Dr. Srijan Kumar
                                </a>
                            </h1>
                            <h2 className="speaker-mini">
                                Srijan Kumar is an Assistant Professor at the College of Computing at 
                                the Georgia Institute of Technology. He completed his postdoctoral 
                                training at Stanford University, received a Ph.D. and M.S. in Computer Science 
                                from the University of Maryland, College Park, and B.Tech. from the Indian 
                                Institute of Technology, Kharagpur. He develops AI, ML, and Data Mining methods 
                                to detect and mitigate the pressing threats posed by malicious actors (e.g., evaders, 
                                sockpuppets, etc.) and harmful content (e.g., misinformation, hate speech etc.) to 
                                web users and platforms. 
                            </h2>
                        </div>

                        <div className="speaker-card card-down">
                            <div className="speaker-bg"></div>
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="speaker-img"
                                src={jake}
                                alt="Jacob Abernethy"
                            />
                            <h1 className="speaker-major">
                                <a
                                className="link"
                                href="https://www.linkedin.com/in/jacob-abernethy-5949533/"
                                rel="noreferrer"
                                target="_blank"
                                >
                                Dr. Jacob Abernethy
                                </a>
                            </h1>
                            <h2 className="speaker-mini">
                                Prof. Abernethy is Assistant Professor in the {" "}
                                <a className="link" rel="noreferrer" href="https://www.scs.gatech.edu/" target="_blank">
                                    School of Computer Science</a> {" "}
                                in the College of Computing at the Georgia Institute of Technology.
                                His research focus is Machine Learning, and he like discovering connections 
                                between Optimization, Statistics, and Economics. He completed his PhD at {" "} 
                                <a href="https://www.berkeley.edu/" target="_blank">UC Berkeley</a> {" "}
                                with Peter Bartlett in 2011, and was a {" "}<a href="https://www.simonsfoundation.org/" target="_blank">
                                Simons postdoctoral fellow</a>{" "} with 
                                Michael Kearns for the following two years.
                            </h2>
                        </div>

                        <div className="speaker-card card-down">
                            <div className="speaker-bg"></div>
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="speaker-img"
                                src={yasin}
                                alt="yahsin ehsan"
                            />
                            <h1 className="speaker-major">
                                <a
                                className="link"
                                href="https://www.linkedin.com/in/yasinehsan/"
                                rel="noreferrer"
                                target="_blank"
                                >
                                Yasin Ehsan
                                </a>
                            </h1>
                            <h2 className="speaker-mini">
                                Yasin is a 13x major hackathon winner getting 1st place @ Hack Cornell, 
                                Hack NYU, Berkeley AI Hacks, JP Morgan Code for Good, and IBM Call for Code. 
                                Yasin was a Former Capital One Senior SWE and Product Manager, led projects 
                                increasing revenue by $200M+, designed two patented AWS optimizations. 
                                Yasin will talk about how to come up with a great idea, how to maximize teams, 
                                how to craft a 2-min pitch, while providing real-life examples and making every 
                                level of hacker feel included and something to look forward to.
                            </h2>
                        </div>

                        <div className="speaker-card card-down">
                            <div className="speaker-bg"></div>
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="speaker-img"
                                src={brandon}
                                alt="brandon barbello"
                            />
                            <h1 className="speaker-major">
                                <a
                                className="link"
                                href="https://www.linkedin.com/in/brandon-barbello-1083b61a/"
                                rel="noreferrer"
                                target="_blank"
                                >
                                Brandon Barbello
                                </a>
                            </h1>
                            <h2 className="speaker-mini">
                                Brandon Barbello is the Founder and COO of Archetype AI, which is building a 
                                next generation foundation model for understanding real world sensor data. 
                                Developers can use Archetype's APIs and tools to rapidly build Physical AI applications.
                                He has 12+ years of experience as a senior product leader working on AI, software, 
                                and hardware products at startups and Google. He has shipped over 20 products to 
                                positive reviews from users and the press worldwide.
                            </h2>
                        </div>

                        <div className="speaker-card card-down">
                            <div className="speaker-bg"></div>
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="speaker-img"
                                src={rahul}
                                alt="rahul nair"
                            />
                            <h1 className="speaker-major">
                                <a
                                className="link"
                                href="https://www.linkedin.com/in/rahulunair/"
                                rel="noreferrer"
                                target="_blank"
                                >
                                Rahul Nair
                                </a>
                            </h1>
                            <h2 className="speaker-mini">
                                Rahul is a Machine Learning Architect at Intel with 10 years of experience in data science,
                                Machine Learning, and applied Deep Learning. He joined Intel after completing his Master’s 
                                degree and has contributed to the OpenStack security group. Rahul has expertise in developing 
                                Deep Learning and Deep Reinforcement Learning algorithms optimized for Intel CPUs. He has 
                                worked on scaling Deep Learning pipelines on the cloud, collaborated with startups and OEMs, 
                                and optimized AI workloads on Intel hardware. 
                            </h2>
                        </div>

                        <div className="speaker-card card-down">
                            <div className="speaker-bg"></div>
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="speaker-img"
                                src={xiquan}
                                alt="xiquan"
                            />
                            <h1 className="speaker-major">
                                <a
                                className="link"
                                href="https://www.linkedin.com/in/xiquan-cui-%E5%B4%94%E5%96%9C%E6%B3%89-2486b115/"
                                rel="noreferrer"
                                target="_blank"
                                >
                                Dr. Xiquan Cui
                                </a>
                            </h1>
                            <h2 className="speaker-mini">
                                Dr. Xiquan Cui serves as the Head of Recommendation and 
                                Personalization Data Science at homedepot.com, where he 
                                leads an elite team of data scientists focused on developing 
                                state-of-the-art AI solutions to solve complex problems in
                                e-commerce and create world-class digital customer experiences. 
                                Xiquan’s research interests include recommender system, information 
                                retrieval, and artificial intelligence.
                            </h2>
                        </div>

                        <div className="speaker-card card-down">
                            <div className="speaker-bg"></div>
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="speaker-img"
                                src={leo}
                                alt="leo howell"
                            />
                            <h1 className="speaker-major">
                                <a
                                className="link"
                                href="https://www.linkedin.com/in/leohowell/"
                                rel="noreferrer"
                                target="_blank"
                                >
                                Leo Howell
                                </a>
                            </h1>
                            <h2 className="speaker-mini">
                                Leo Howell, a visionary IT leader with over two decades of experience, currently serves as the 
                                interim vice president for IT and chief information officer at Georgia Tech. In this role, he 
                                provides vision, leadership, and oversight in the development and implementation of information 
                                technology. He is also responsible for establishing the strategic direction of how the university 
                                handles its information technology resources. He formerly served as the Institute's chief information 
                                security officer (CISO).
                            </h2>
                        </div>

                        <div className="speaker-card card-down">
                            <div className="speaker-bg"></div>
                            <LazyLoadImage
                                threshold={400}
                                effect="blur"
                                className="speaker-img"
                                src={alfredo}
                                alt="alfredo deza"
                            />
                            <h1 className="speaker-major">
                                <a
                                className="link"
                                href="https://www.linkedin.com/in/alfredodeza/"
                                rel="noreferrer"
                                target="_blank"
                                >
                                Alfredo Deza
                                </a>
                            </h1>
                            <h2 className="speaker-mini">
                            Alfredo Deza is a software engineer, speaker, author, and former Olympic athlete working as a Cloud Advocate 
                            for Microsoft. He has written several books about programming languages and artificial intelligence, and
                            has created online courses about the cloud and machine learning. He currently is an Adjunct Professor at 
                            Duke University, and as part of his role, works closely with universities around the world like Georgia Tech, 
                            Duke University, and Carnegie Mellon, where he often gives guest lectures about technology.
                            </h2>
                        </div>
                    </div>
                </div>
                <a className="continue-reading" href="#team">
                    <span className="text">Team</span>
                    <span className="arrow">↓</span>
                </a>
            </Section>

            <Section id="team" Coordinates="True">
                <div className="team-flex">
                    <h1 className="major">Team</h1>
                    <h2 className="mini">Click to connect on Linkedin</h2>
                    <div className="team-grid">
                        <div className="team-member-card">
                            <div className="img-container">
                                <LazyLoadImage
                                    threshold={400}
                                    effect="blur"
                                    className="team-member-img"
                                    src={krishi}
                                    alt="krishi manek"
                                />
                            </div>
                            <div className="nametitle">
                                <a href="https://www.linkedin.com/in/krishimanek9/" target="_blank">
                                    <h2 className="name">Krishi Manek</h2>
                                </a>
                                <h3 className="title">President, DSGT</h3>
                            </div>
                        </div>

                        <div className="team-member-card">
                            <div className="img-container">
                                <LazyLoadImage
                                    threshold={400}
                                    effect="blur"
                                    className="team-member-img"
                                    src={vicente}
                                    alt="vicente miranda"
                                />
                            </div>
                            <div className="nametitle">
                                <a href="https://www.linkedin.com/in/vicente3j/" target="_blank">
                                    <h2 className="name">Vicente Miranda</h2>
                                </a>
                                <h3 className="title">Director of Technology, DSGT</h3>
                            </div>
                        </div>

                        <div className="team-member-card">
                            <div className="img-container">
                                <LazyLoadImage
                                    threshold={400}
                                    effect="blur"
                                    className="team-member-img"
                                    src={emma}
                                    alt="emma lawton"
                                />
                            </div>
                            <div className="nametitle">
                                <a href="https://www.linkedin.com/in/emma-lawton-b3aa6b225/" target="_blank">
                                    <h2 className="name">Emma Lawton</h2>
                                </a>
                                <h3 className="title">Co-director of Hacklytics, DSGT</h3>
                            </div>
                        </div>

                        <div className="team-member-card">
                            <div className="img-container">
                                <LazyLoadImage
                                    threshold={400}
                                    effect="blur"
                                    className="team-member-img"
                                    src={archie}
                                    alt="archie goli"
                                />
                            </div>
                            <div className="nametitle">
                                <a href="https://www.linkedin.com/in/archishma-goli/" target="_blank">
                                    <h2 className="name">Archie Goli</h2>
                                </a>
                                <h3 className="title">Co-director of Hacklytics, DSGT</h3>
                            </div>
                        </div>

                        <div className="team-member-card">
                            <div className="img-container">
                                <LazyLoadImage
                                    threshold={400}
                                    effect="blur"
                                    className="team-member-img"
                                    src={shreiyas}
                                    alt="shreiyas saraf"
                                />
                            </div>
                            <div className="nametitle">
                                <a href="https://www.linkedin.com/in/shreiyas-saraf/" target="_blank">
                                    <h2 className="name">Shreiyas Saraf</h2>
                                </a>
                                <h3 className="title">Director of External Affairs</h3>
                            </div>
                        </div>

                        <div className="team-member-card">
                            <div className="img-container">
                                <LazyLoadImage
                                    threshold={400}
                                    effect="blur"
                                    className="team-member-img"
                                    src={john}
                                    alt="john ramberger"
                                />
                            </div>
                            <div className="nametitle">
                                <a href="https://www.linkedin.com/in/johnramberger/" target="_blank">
                                    <h2 className="name">John Ramberger</h2>
                                </a>
                                <h3 className="title">Ex-director of Technology, DSGT</h3>
                            </div>
                        </div>

                    </div>
                </div>
                <a className="continue-reading" href="#sponsors">
                    <span className="text">Sponsors</span>
                    <span className="arrow">↓</span>
                </a>
            </Section>

            <Section id="sponsors">
                <div className="pastsponsors-flex">
                    <h1 className="major">Sponsors</h1>
                    <div className="sponsors-flex">
                        {/* <a className="sponsor-largest" href="https://www.intel.com/content/www/us/en/developer/tools/devcloud/overview.html" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={inteldev}
                                alt="Intel Developer Cloud"
                            />
                        </a> */}
                        <a className="sponsor-large" href="https://www.intel.com/content/www/us/en/homepage.html" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={intel}
                                alt="Intel"
                            />
                        </a>
                        <a className="sponsor" href="https://www.nsa.gov/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={nsa}
                                alt="National Security Agency"
                            />
                        </a>
                        <a className="sponsor-large" href="https://www.assurant.com/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor-larger"
                                src={assurant}
                                alt="assurant"
                            />
                        </a>
                        <a className="sponsor-larger" href="https://www.carelon.com/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={carelon}
                                alt="Carelon"
                            />
                        </a>
                        <a className="sponsor-larger" href="https://careers.microsoft.com/v2/global/en/students" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={microsoft}
                                alt="microsoft"
                            />
                        </a>
                        <a className="sponsor-large" href="https://www.deshaw.com/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={deshawco}
                                alt="deshawco"
                            />
                        </a>
                        <a className="sponsor-small" href="https://www.deere.com/en/index.html" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={johndeere}
                                alt="john deere"
                            />
                        </a>
                        <a className="sponsor-larger" href="https://www.connectwise.com/platform/on-demand-demos?mcid=PPC6350&utm_medium=PPC&utm_source=Google&utm_campaign=PPC6350&utm_content=demo&loc=NA&cq_cmp=15836773817&cq_plac=&cq_net=g&utm_term=connectwise&_bt=689022999050&_bk=connectwise&_bm=e&_bn=g&_bg=135619274361&cq_cmp=15836773817&cq_plac=&cq_net=g&gad_source=1&gclid=CjwKCAiA8YyuBhBSEiwA5R3-E-1dzfx_PYPJVcn8HpV4cbrDdDcFbvse13qD21YKXF2TkLMrsW8HkhoCZv0QAvD_BwE&gclsrc=aw.ds" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={connectwise}
                                alt="connectwise"
                            />
                        </a>
                        <a className="sponsor" href="https://www.elastic.co/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={elastic}
                                alt="elastic"
                            />
                        </a>
                        <a className="sponsor-smaller" href="https://create-x.gatech.edu/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={createx}
                                alt="createx"
                            />
                        </a>
                        <a className="sponsor" href="https://soonami.io/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={soonami}
                                alt="soonami"
                            />
                        </a>
                        <a className="sponsor-smaller" href="https://www.aws.amazon.com/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={amazon}
                                alt="Amazon"
                            />
                        </a>
                        <a className="sponsor" href="https://www.archetypeai.io/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={archetype}
                                alt="archetype ai"
                            />
                        </a>
                        <a className="sponsor" href="https://traversaal.ai/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={traversaal}
                                alt="traversaal"
                            />
                        </a>
                        <a className="sponsor" href="https://theheadstarter.com/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={headstarter}
                                alt="headstarter"
                            />
                        </a>
                        <a className="sponsor-smaller" href="https://telora.com/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={telora}
                                alt="telora"
                            />
                        </a>
                        <a className="sponsor-smaller" href="https://cloud.google.com/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={googlecloud}
                                alt="googlecloud"
                            />
                        </a>
                        <a className="sponsor-smaller" href="https://about.google/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={google}
                                alt="google"
                            />
                        </a>
                        <a className="sponsor" href="https://www.wolfram.com/language/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={wolfram}
                                alt="wolfram"
                            />
                        </a>
                        <a className="sponsor" href="https://brightdata.com/?kw=bright%20data&cpn=12540480407&utm_matchtype=e&utm_matchtype=e&cq_src=google_ads&cq_cmp=12540480407&cq_term=bright%20data&cq_plac=&cq_net=g&cq_plt=gp&utm_term=bright%20data&utm_campaign=brand_brightdata-all_geos-search_brand-kw_en-desktop&utm_source=adwords&utm_medium=ppc&utm_content=bright-data-proxy&hsa_acc=1393175403&hsa_cam=12540480407&hsa_grp=120400519099&hsa_ad=605257297612&hsa_src=g&hsa_tgt=kwd-1198517744079&hsa_kw=bright%20data&hsa_mt=e&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=Cj0KCQiAwvKtBhDrARIsAJj-kTisYAJM4K1zSLmVwCxMO8nPET006wVJvjVnrIeWpSTKjOsphiPTzDwaAjkSEALw_wcB" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={brightdata}
                                alt="brightdata"
                            />
                        </a>
                        {/* <a className="sponsor-small" href="https://www.kineticatlanta.com/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={kinetic}
                                alt="kinetic atlanta"
                            />
                        </a> */}
                    </div>
                    <h1 className="minor" style={{marginTop: '1em'}}>Community Partners</h1>
                    <div className="sponsors-flex">
                        <a className="sponsor-large" href="https://www.startup.exchange/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={sxlogotext}
                                alt="Startup Exchange"
                            />
                        </a>
                        <a className="sponsor-small" href="https://www.isye.gatech.edu/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={isye}
                                alt="isye"
                            />
                        </a>
                        <a className="sponsor-larger" href="https://www.scheller.gatech.edu/index.html" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={scheller2}
                                alt="scheller"
                            />
                        </a>
                        <a className="sponsor-small" href="https://www.cc.gatech.edu/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={coc}
                                alt="coc"
                            />
                        </a>
                        <a className="sponsor-small" href="https://datax.gatech.edu/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={datadays}
                                alt="data days"
                            />
                        </a>
                        <a className="sponsor-larger" href="https://research.gatech.edu/data" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={ideas}
                                alt="ideas"
                            />
                        </a>
                    </div>
                </div>
            </Section>
            <Footer />
        </div>
    );
};

export default Home;