import LazyLoad from "react-lazyload";
import "./Clouds.scss";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import cloud1 from "../../Assets/Images/Other/cloud1.png";
import cloud3 from "../../Assets/Images/Other/cloud3.png";

const Clouds = (params) => {
    return (
        <div id="clouds">
            {params.ScrollDirection === "Left" ? (
                <div className="marquee">
                    <LazyLoadImage
                        className="scrolling-cloud-left"
                        src={cloud1}
                        alt="cloud1"
                        height={800}
                        width={800}
                    />
                </div>
            ) : (
                <LazyLoadImage
                    className="scrolling-cloud-right"
                    src={cloud3}
                    alt="cloud3"
                    height={400}
                    width={400}
                />
            )}
        </div>
    );
};

export default Clouds;