import { useState } from "react";
import "./ScheduleSection.scss";

import airplane from "../../Assets/Images/Other/airplane.png";

const ScheduleSection = (params) => {
    const [day, setDay] = useState(1);

    return (
        <div className="events-schedule-section">
            <div className="tabs">
                <div className="schedule-tabs">
                    <button
                        className={`${"tab-button"} ${day === 1 ? "_active" : ""}`}
                        onClick={() => {
                            if (day !== 1) setDay(1);
                        }}
                        >
                        Friday
                    </button>

                    <button
                        className={`${"tab-button"} ${day === 2 ? "_active" : ""}`}
                        onClick={() => {
                            if (day !== 2) setDay(2);
                        }}
                        >
                        Saturday
                    </button>

                    <button
                        className={`${"tab-button"} ${day === 3 ? "_active" : ""}`}
                        onClick={() => {
                            if (day !== 3) setDay(3);
                        }}
                        >
                        Sunday
                    </button>
                    <span className="slider" role="presentation"></span>
                </div>
            </div>

            <table className="schedule-table">
                <tbody>
                    {day === 1 ? (
                    <>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">4:00pm</td>
                            <td className="event">Check-in starts</td>
                            <td className="location">Klaus Atrium</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">6:00pm - 6:45pm</td>
                            <td className="event">Opening Ceremony</td>
                            <td className="location">Klaus 1443, 2443</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">7:00pm - 8:15pm</td>
                            <td className="event">Sponsor Fair</td>
                            <td className="location">Klaus Atrium</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">8:00pm - 9:00pm</td>
                            <td className="event">Friday Dinner</td>
                            <td className="location">Klaus Atrium</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">9:00pm</td>
                            <td className="event">Hacking starts</td>
                            <td className="location">Klaus Atrium</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">10:00pm - 11:30pm</td>
                            <td className="event">Intel Workshop</td>
                            <td className="location">Klaus 1443</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">11:45pm</td>
                            <td className="event">Friday Midnight Snack</td>
                            <td className="location">Klaus 1116</td>
                        </tr>
                    </>
                    ) : null}
                    {day === 2 ? (
                    <>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">8:30am - 9:30am</td>
                            <td className="event">Saturday Breakfast</td>
                            <td className="location">Klaus 1116</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">9:30am - 10:30am</td>
                            <td className="event">NSA Workshop</td>
                            <td className="location">Klaus 1443</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">10:30am - 11:00am</td>
                            <td className="event">How to Start a Startup by Eliam Medina</td>
                            <td className="location">Klaus 1443</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">11:00am - 12:30pm</td>
                            <td className="event">Amazon Workshop</td>
                            <td className="location">Klaus 1443</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">1:00pm - 2:00pm</td>
                            <td className="event">Saturday Lunch</td>
                            <td className="location">Klaus 1116</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">2:00pm - 3:00pm</td>
                            <td className="event">Tableau Workshop by Salesforce</td>
                            <td className="location">Klaus 2443</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">3:00pm - 4:00pm</td>
                            <td className="event">Intel Liftoff & Create-X Workshop</td>
                            <td className="location">Klaus 1443</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">4:00pm - 5:00pm</td>
                            <td className="event">Twinkie Challenge</td>
                            <td className="location">Klaus Atrium</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">5:00pm - 6:00pm</td>
                            <td className="event">Slideshow Karaoke with MLH</td>
                            <td className="location">Klaus Atrium</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">6:00pm - 7:00pm</td>
                            <td className="event">Generative AI Speaking Panel</td>
                            <td className="location">Klaus 1443</td>
                        </tr>
                        <tr>
                            <td className="img-container">
                                <img
                                    className="airplane"
                                    src={airplane}
                                    alt="airplane-pic"
                                />
                            </td>
                            <td className="time">8:00pm - 9:00pm</td>
                            <td className="event">Saturday Dinner</td>
                            <td className="location">Klaus 1116</td>
                        </tr>
                        <tr>
                        <td className="img-container">
                            <img
                                className="airplane"
                                src={airplane}
                                alt="airplane-pic"
                            />
                        </td>
                        <td className="time">11:45pm</td>
                        <td className="event">Saturday Midnight Snack</td>
                        <td className="location">Klaus 1116</td>
                    </tr>
                    </>
                    ) : null}
                    {day === 3 ? (
                    <>
                    <tr>
                        <td className="img-container">
                            <img
                                className="airplane"
                                src={airplane}
                                alt="airplane-pic"
                            />
                        </td>
                        <td className="time">9:00am</td>
                        <td className="event">Hacking ends</td>
                        <td className="location">Klaus Atrium</td>
                    </tr>
                    <tr>
                        <td className="img-container">
                            <img
                                className="airplane"
                                src={airplane}
                                alt="airplane-pic"
                            />
                        </td>
                        <td className="time">9:30am - 10:30am</td>
                        <td className="event">Sunday Breakfast</td>
                        <td className="location">Klaus 1116</td>
                    </tr>
                    <tr>
                        <td className="img-container">
                            <img
                                className="airplane"
                                src={airplane}
                                alt="airplane-pic"
                            />
                        </td>
                        <td className="time">11:00am - 1:00pm</td>
                        <td className="event">Judging: Project Expo</td>
                        <td className="location">Klaus Atrium</td>
                    </tr>
                    <tr>
                        <td className="img-container">
                            <img
                                className="airplane"
                                src={airplane}
                                alt="airplane-pic"
                            />
                        </td>
                        <td className="time">1:30pm - 2:30pm</td>
                        <td className="event">Sunday Lunch</td>
                        <td className="location">Klaus 1116</td>
                    </tr>
                    <tr>
                        <td className="img-container">
                            <img
                                className="airplane"
                                src={airplane}
                                alt="airplane-pic"
                            />
                        </td>
                        <td className="time">3:00pm - 4:00pm</td>
                        <td className="event">Closing Ceremony</td>
                        <td className="location">Klaus 1443, Klaus 2443</td>
                    </tr>
                </>
                    ) : null}
                </tbody>
            </table>
        </div>
    )
};

export default ScheduleSection;

