import "./TrackCard.scss";
import airplane from "../../Assets/Images/Other/airplane.png";

const TrackCard = (params) => {
    return (
        <div className="track-card">
            <input
                className="card-check"
                id={"track-card-" + params.identifier}
                type="checkbox"
            />
            <label 
                htmlFor={"track-card-" + params.identifier}
                className="track-card-inner"
            >
                <div className="track-card-front">
                    <div className="airplane-left">
                        <img
                            src={airplane}
                            alt="airplane-pic"
                        />
                    </div>
                    <div className="front-style">
                        <h1 className="major-card">{params.track}</h1>
                        <h2 className="major-card-sub">{params.subtext}</h2>
                    </div>
                    <div className="airplane-right">
                        <img
                            src={airplane}
                            alt="airplane-pic"
                        />
                    </div>
                </div>
                
                <div className="track-card-back">
                    <p>{params.children}</p>
                </div>
            </label>    
        </div>
    );
};

export default TrackCard;