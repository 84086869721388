import "./Coordinates.scss";
import React from "react";

const Coordinates = (params) => {
    return (
        <div id="coordinates">
            {params.ScrollDirection === "Left" ? (
                <div className="marquee">
                    <p className="scrolling-text-left">
                        48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦ 48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦ 48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦
                    </p>
                    <p className="scrolling-text-left">
                        48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦ 48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦ 48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦
                    </p>
                </div>
            ) : (
                <div className="marquee">
                    <p className="scrolling-text-right">
                        48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦ 48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦ 48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦
                    </p>
                    <p className="scrolling-text-right">
                        48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦ 48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦ 48.8584° N, 2.2945° E ✦ 48.8606° N, 2.3376° E ✦ 48.8738° N, 2.2950° E ✦ 48.8530° N, 2.3499° E ✦
                    </p>
                </div>
            )}
        </div>
    );
};

export default Coordinates;